
.whoContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;

}
.whoContainer img {
    width: 100%;
    height: 100%;

}
.personOne{
    display: flex;
    flex-direction: row;
    width: 50%;
    height: 50%;
    /* margin: 10px; */
    /* background-color: white; */
    margin:0 10px 0 50px;
    border: 1px solid black;

}
.personTwo{
    display: flex;
    flex-direction: row;
    width: 50%;
    height: 50%;
    /* margin: 10px; */
    /* background-color: white; */
    margin: 0 10px 0 50px;
    border: 1px solid black;
}
.bioText {
    height: 50%;
    width: 50%;
    object-fit: cover;
    overflow: scroll;
    padding:0 10px 10px 10px;
    background: rgba(226, 225, 225, 0.6);
    border: 1px solid black;
    margin: 0 20px 0 0;
    max-height: 400px;

}
.pageNavigation{
    position: fixed;
    top: 70px;
    left: 80px;
    font-size: 20px;
}





/* -----why container for concept---- */
::-webkit-scrollbar {
    width: 0px;  /* remove scrollbar space */
    background: transparent;  /* optional: just make scrollbar invisible */
}
.whyContainer{
    position: absolute;
    top: 150px;
    left: 30px;
    /* margin: 0 100px 100px 0; */
    max-height: 70vh;
    width: 80%;
    /* height: 70%;  */
    background-color: rgba(65, 65, 65, 0.7);
    object-fit: cover;
    overflow-y: scroll;
    border: 1px solid black;
    padding: 15px;
}

.spanishTextBio{
        color: rgb(12, 12, 12);
        font-style: italic;
        margin-top: 20px;
        border-top: 1px solid rgb(7, 7, 7);
        padding-top: 20px;
}
.italicPlain{
    font-style: italic;
}
.boldText{
    font-weight: bolder;
}