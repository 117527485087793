.home-grid {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    object-fit: contain;
    position: absolute;
    z-index: 10;
    /* opacity: 0.5; */
}
@media (max-width: 600px){
    .home-grid {
        width: 100vw;
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        object-fit: contain;
        position: absolute;
        z-index: 10;
        /* opacity: 0.5; */
    }
}
.grid-item-link:hover .grid-card img{
    -webkit-transition: 1s opacity;
    transition: 1s opacity;
    opacity: 100;
}
.grid-item-link:hover .landingPageTitle{
    opacity: 100;
}

.grid-card {
    height: 50vh;
    width: 100%;
    position: relative;
    border: 1px solid white;
}
@media (max-width: 600px){
    .grid-card {
        height: 100%;
        width: 100%;
        position: relative;
        border: 1px solid white;
    }
}

.grid-card img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
    /* filter: grayscale(10); */
    /* opacity: 0.5; */
}

.grid-item-link {
    z-index: -10;
}
.landingPageTitle{
    position: absolute;
    bottom: 30px;
    left: 20px;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: rgb(154, 144, 144);
    opacity: 0;
}
/* .wrapper {
    height: 100vh;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
} */

.logo img{
    position: absolute;
    top: 5px;
    left: 10px;
    width: 10em;
    height: 10em;
    z-index: 1000;
}

.background-image {
    position: absolute;
    object-fit: cover;
    height: 100vh;
    width: 100vw;
    z-index: -999;
}
@media (max-width: 500px){
    .background-image{
        position: fixed;
        object-fit: cover;
        height: 100vh;
        width: 100%;
        z-index: -999;
    }
}

.page-container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


nav h1 {
    position: fixed;
    margin: 0;
    padding: 0;
    top: 1em;
    left: 1em;
    font-size: 2rem;
    font-weight: bolder;
    color: var(--color-black);
    z-index: 20;
}
nav h1:hover{
  color: rgb(204, 201, 201);
}
.hamPosition{   
    font-size: 1.3em;
    line-height: 1.8em;
    font-weight: bolder;
    opacity: .8;
    z-index: 200;
}


#App {
  height: 100vh;
}

#page-wrap {
  text-align: center;
  overflow: auto;
}

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 30px;
}

.bm-burger-bars {
  background: #000000;
}
.bm-burger-button:hover .bm-burger-bars {
  background: white;
}
.bm-cross-button {
  height: 40px;
  width: 40px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
}

.bm-overlay {
  background: rgba(88, 84, 84, 0.3);
}


.project-container {
    display: flex;
    flex-direction: column;
    
}
@media (max-width: 500px){
    .project-container{
        position: absolute;
        top: 50px;
    }
}
.project-title {
    font-size: 2.8rem;
    margin: 0 0 20px -20px;
    padding: 0;

}

.project-title-color-white {
    color: var(--color-black);
}

.project-title-color-black {
    color: var(--color-black);
}
@media (max-width: 1087px){
    .project-title {
        font-size: 2.8rem;
        margin: 10% 0 20px 20px;
        padding: 0;
    
    }
}
/* .iconTitle{
    position: absolute;
    transform: rotate(90deg);
    margin: 35px 0 0 60px;
} */
@media (max-width: 1087px){
    .icons{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }
}


.whyDescriptionMariaAndEric{

    color: rgb(238, 237, 237);
    font-size: 17px;

    
}
.whyDescriptionMariaAndEricSpanish{
    color: rgb(255, 255, 255);
    font-style: italic;
    margin-top: 20px;
    border-top: 1px solid white;
    padding-top: 20px;
}
.whyDescriptionMariaAndEricSpanish h3 strong
.whyDescriptionMariaAndEric h3 strong{
    font-weight: bold;
}


.whoContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;

}
.whoContainer img {
    width: 100%;
    height: 100%;

}
.personOne{
    display: flex;
    flex-direction: row;
    width: 50%;
    height: 50%;
    /* margin: 10px; */
    /* background-color: white; */
    margin:0 10px 0 50px;
    border: 1px solid black;

}
.personTwo{
    display: flex;
    flex-direction: row;
    width: 50%;
    height: 50%;
    /* margin: 10px; */
    /* background-color: white; */
    margin: 0 10px 0 50px;
    border: 1px solid black;
}
.bioText {
    height: 50%;
    width: 50%;
    object-fit: cover;
    overflow: scroll;
    padding:0 10px 10px 10px;
    background: rgba(226, 225, 225, 0.6);
    border: 1px solid black;
    margin: 0 20px 0 0;
    max-height: 400px;

}
.pageNavigation{
    position: fixed;
    top: 70px;
    left: 80px;
    font-size: 20px;
}





/* -----why container for concept---- */
::-webkit-scrollbar {
    width: 0px;  /* remove scrollbar space */
    background: transparent;  /* optional: just make scrollbar invisible */
}
.whyContainer{
    position: absolute;
    top: 150px;
    left: 30px;
    /* margin: 0 100px 100px 0; */
    max-height: 70vh;
    width: 80%;
    /* height: 70%;  */
    background-color: rgba(65, 65, 65, 0.7);
    object-fit: cover;
    overflow-y: scroll;
    border: 1px solid black;
    padding: 15px;
}

.spanishTextBio{
        color: rgb(12, 12, 12);
        font-style: italic;
        margin-top: 20px;
        border-top: 1px solid rgb(7, 7, 7);
        padding-top: 20px;
}
.italicPlain{
    font-style: italic;
}
.boldText{
    font-weight: bolder;
}
.image-page-container {
    padding: 6em 2em 2em 2em;
}




.whyDescriptionBenAndBen{

    color: rgb(238, 237, 237);
    font-size: 17px;


}
.whyDescriptionBenAndBenSpanish{
    color: rgb(255, 255, 255);
    font-style: italic;
    margin-top: 20px;
    border-top: 1px solid white;
    padding-top: 20px;
}
.whyDescriptionBenAndBenSpanish h3 strong
.whyDescriptionBenAndBen h3 strong{
    font-weight: bold;
}







.whyDescriptionChipAndMark{

    color: rgb(238, 237, 237);
    font-size: 17px;

    
}
.whyDescriptionChipAndMarkSpanish{
    color: rgb(255, 255, 255);
    font-style: italic;
    margin-top: 20px;
    border-top: 1px solid white;
    padding-top: 20px;
}
.whyDescriptionChipAndMarkSpanish h3 strong
.whyDescriptionChipAndMark h3 strong{
    font-weight: bold;
}

.whyDescriptionHeatherAndTaylor{

    color: rgb(238, 237, 237);
    font-size: 17px;


}
.whyDescriptionHeatherAndTaylorSpanish{
    color: rgb(255, 255, 255);
    font-style: italic;
    margin-top: 20px;
    border-top: 1px solid white;
    padding-top: 20px;
}
.whyDescriptionHeatherAndTaylorSpanish h3 strong
.whyDescriptionHeatherAndTaylor h3 strong{
    font-weight: bold;
}


.KandMwhyDescription{

    color: rgb(238, 237, 237);
    font-size: 17px;

    
}
.KandMwhyDescriptionSpanish{
    color: rgb(255, 255, 255);
    font-style: italic;
    margin-top: 20px;
    border-top: 1px solid white;
    padding-top: 20px;
}
.KandMwhyDescription h3 strong,
.KandMwhyDescriptionSpanish h3 strong{
    font-weight: bold;
}

.whyDescriptionTomAndScottEnglish{
    color: rgb(238, 237, 237);
    font-size: 17px;
    padding: 10px;
}
.whyDescriptionTomAndScottSpanish{
    /* background-color: rgba(255, 255, 255, 0.7); */
    color: rgb(255, 255, 255);
    padding: 10px;
    font-style: italic;
    margin-top: 20px;
    border-top: 1px solid white;
    padding-top: 20px;
}

.whyDescriptionTomAndScottEnglish h3 strong,
.whyDescriptionTomAndScottSpanish h3 strong{
    font-weight: bold;
    /* color: rgb(245, 237, 237); */
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Chivo', sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; /* Safari/Chrome, other WebKit */    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

code {
    font-family: 'Chivo', sans-serif;

}

:root {
    --color-black: black;
    --color-white: white;
}

