.background-image {
    position: absolute;
    object-fit: cover;
    height: 100vh;
    width: 100vw;
    z-index: -999;
}
@media (max-width: 500px){
    .background-image{
        position: fixed;
        object-fit: cover;
        height: 100vh;
        width: 100%;
        z-index: -999;
    }
}
