.grid-item-link:hover .grid-card img{
    transition: 1s opacity;
    opacity: 100;
}
.grid-item-link:hover .landingPageTitle{
    opacity: 100;
}

.grid-card {
    height: 50vh;
    width: 100%;
    position: relative;
    border: 1px solid white;
}
@media (max-width: 600px){
    .grid-card {
        height: 100%;
        width: 100%;
        position: relative;
        border: 1px solid white;
    }
}

.grid-card img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
    /* filter: grayscale(10); */
    /* opacity: 0.5; */
}

.grid-item-link {
    z-index: -10;
}
.landingPageTitle{
    position: absolute;
    bottom: 30px;
    left: 20px;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: rgb(154, 144, 144);
    opacity: 0;
}