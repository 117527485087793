/* .wrapper {
    height: 100vh;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
} */

.logo img{
    position: absolute;
    top: 5px;
    left: 10px;
    width: 10em;
    height: 10em;
    z-index: 1000;
}
