
.whyDescriptionTomAndScottEnglish{
    color: rgb(238, 237, 237);
    font-size: 17px;
    padding: 10px;
}
.whyDescriptionTomAndScottSpanish{
    /* background-color: rgba(255, 255, 255, 0.7); */
    color: rgb(255, 255, 255);
    padding: 10px;
    font-style: italic;
    margin-top: 20px;
    border-top: 1px solid white;
    padding-top: 20px;
}

.whyDescriptionTomAndScottEnglish h3 strong,
.whyDescriptionTomAndScottSpanish h3 strong{
    font-weight: bold;
    /* color: rgb(245, 237, 237); */
}