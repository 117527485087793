
.whyDescriptionBenAndBen{

    color: rgb(238, 237, 237);
    font-size: 17px;


}
.whyDescriptionBenAndBenSpanish{
    color: rgb(255, 255, 255);
    font-style: italic;
    margin-top: 20px;
    border-top: 1px solid white;
    padding-top: 20px;
}
.whyDescriptionBenAndBenSpanish h3 strong
.whyDescriptionBenAndBen h3 strong{
    font-weight: bold;
}





