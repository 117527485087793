/* .iconTitle{
    position: absolute;
    transform: rotate(90deg);
    margin: 35px 0 0 60px;
} */
@media (max-width: 1087px){
    .icons{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }
}
