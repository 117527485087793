.project-container {
    display: flex;
    flex-direction: column;
    
}
@media (max-width: 500px){
    .project-container{
        position: absolute;
        top: 50px;
    }
}