.project-title {
    font-size: 2.8rem;
    margin: 0 0 20px -20px;
    padding: 0;

}

.project-title-color-white {
    color: var(--color-black);
}

.project-title-color-black {
    color: var(--color-black);
}
@media (max-width: 1087px){
    .project-title {
        font-size: 2.8rem;
        margin: 10% 0 20px 20px;
        padding: 0;
    
    }
}