body {
    margin: 0;
    padding: 0;
    font-family: 'Chivo', sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

code {
    font-family: 'Chivo', sans-serif;

}

:root {
    --color-black: black;
    --color-white: white;
}
