.home-grid {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    object-fit: contain;
    position: absolute;
    z-index: 10;
    /* opacity: 0.5; */
}
@media (max-width: 600px){
    .home-grid {
        width: 100vw;
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        object-fit: contain;
        position: absolute;
        z-index: 10;
        /* opacity: 0.5; */
    }
}