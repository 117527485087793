nav h1 {
    position: fixed;
    margin: 0;
    padding: 0;
    top: 1em;
    left: 1em;
    font-size: 2rem;
    font-weight: bolder;
    color: var(--color-black);
    z-index: 20;
}
nav h1:hover{
  color: rgb(204, 201, 201);
}
.hamPosition{   
    font-size: 1.3em;
    line-height: 1.8em;
    font-weight: bolder;
    opacity: .8;
    z-index: 200;
}


#App {
  height: 100vh;
}

#page-wrap {
  text-align: center;
  overflow: auto;
}

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 30px;
}

.bm-burger-bars {
  background: #000000;
}
.bm-burger-button:hover .bm-burger-bars {
  background: white;
}
.bm-cross-button {
  height: 40px;
  width: 40px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
}

.bm-overlay {
  background: rgba(88, 84, 84, 0.3);
}
